import React, { Component } from 'react'
import styles from './contacts.module.css'
import {GAME_ASSETS, GetCharacterAsset, GetCharacterCodename, GetCharacterEyeHeight, GetCharacterName} from "../globals";
import LazyLoad from 'react-lazyload';
import i18n from '../locales/i18n';
import PlayerElement from './playerElement';
import PlayerModal from './playermodal';
import { Box, Grid, TextField, InputAdornment, IconButton, Container } from '@material-ui/core';
import SearchIcon from "@material-ui/icons/Search";
import { PureComponent } from 'react';
import {Helmet} from "react-helmet";
import countriesJson from '../locales/countries.json';
import Autocomplete from '@material-ui/lab/Autocomplete';

const fuzzysort = require('fuzzysort')

class Players extends Component {
  state = {
    playerModalOpened: false,
    playerModalPlayer: null,
    league: null,
    players: [],
    filtered: [],
    search: ""
  }

  constructor(){
    super();
    this.playerModal = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if(prevProps != this.props && (prevProps.game != this.props.game || !prevProps.allplayers || prevProps.allplayers.length == 0 || !prevProps.allplayers.players || !this.props.allplayers || this.state.players.length == 0)){
      this.setState({
        playerModalOpened: false,
        players: [],
        filtered: [],
        search: ""
      })
      this.fetchPlayers();
    }
  }

  componentDidMount() {
    this.fetchPlayers();
  }
  
  fetchPlayers() {
    if(this.props.allplayers && this.props.allplayers.players && Object.keys(this.props.allplayers.players).length > 0){
      let players = [];

      let ap = this.props.players ? this.props.players : this.props.allplayers["players"];

      ap.forEach(function(p){

        p.avatar = "/icons/avatar_generic.png";

        if(p.smashgg_image) {
          p.avatar = p.smashgg_image;
        }

        if(!p.mains || p.mains.length == 0 || p.mains[0] == ""){
          p.mains = ["Random"]
        }

        p.mainnames = p.mains.join(" ");

        if(this.props.match){
          if(this.props.match.params["id"]){
            if(p.braacket_links){
              p.braacket_links.forEach(link => {
                let linkLeague = link.split(":")[0];
                let linkId = link.split(":")[1];
      
                if(linkLeague == this.props.match.params["id"]){
                  p.league_id = linkId;
                }
              })
            }
          } else {
            if(p.braacket_links.length > 0){
              p.league_id = p.braacket_links[0];
            } else {
              p.league_id = 0;
            }
          }
        }

        players.push(p);
      }, this)

      players.sort((a, b) => {return a.org+a.name > b.org+b.name ? -1 : 1});

      this.setState({players: players, filtered: players})
    }
  }

  getCharCodename(playerData, id){
    let skin = 0;

    if(playerData.hasOwnProperty("skins")){
      skin = playerData["skins"][playerData["mains"][id]];
      if(skin == undefined){
        skin = 0;
      }
    }
    
    return GetCharacterCodename(this.props.game, playerData["mains"][id])+"_0"+skin;
  }

  getCharName(name){
    return name.toLowerCase().replace(/ /g, "");
  }

  getTwitterHandle(twitter){
    let parts = twitter.split('/');
    return parts[parts.length-1];
  }

  normalizePlayerName(name){
    return name.normalize("NFKD").replace(/ /g, '_').replace(/@/g, "_At_").replace(/~/g, "_Tilde_").replace(RegExp('[^0-9a-zA-Z_-]'), '').replace("|", "")
  }

  openPlayerModal(player){
    this.props.history.push(
      player.league_id
    );
    
    this.playerModal.current.setState({open: true, player: player});
  }

  closePlayerModal(historyGoBack=true){
    this.setState({playerModalOpened: false});

    if(historyGoBack){
      this.props.history.goBack();
    }
  }

  search(e){
    if(e != null) this.state.search = e;

    this.state.filtered = this.state.players;

    if(this.state.filterCountry){
      this.state.filtered = this.state.filtered.filter((p)=>{return p.country_code == this.state.filterCountry[0]});
    }

    if(this.state.filterCharacter){
      this.state.filtered = this.state.filtered.filter((p)=>{return GetCharacterName(this.props.game, p.mains[0] || "Random") == this.state.filterCharacter[0]});
    }
    
    if(this.state.search.length > 0){
      let result = fuzzysort.go(this.state.search, Object.values(this.state.filtered), {
        keys: [
          'name',
          'full_name',
          'org',
          'country',
          'state',
          'mainnames'
        ],
        threshold: -10000,
        limit: 20,
        scoreFn(a){
          return Math.max(
            a[0]?a[0].score:-1000,
            a[1]?a[1].score-10:-1000,
            a[2]?a[2].score-10:-1000,
            a[3]?a[3].score-100:-1000,
            a[4]?a[4].score-100:-1000,
            a[5]?a[5].score-10:-1000,
          )}
      })
      this.state.filtered = []
      Object.values(result).forEach((val)=>{
        if (val["obj"] != null)
          this.state.filtered.push(val["obj"]);
      })
    }

    this.setState(this.state);
  }

  render (){
    return(
      <Container maxWidth="lg" disableGutters>
        <Helmet>
          <meta property="og:title" content="Players - PowerRankings.gg" />
          <meta property="og:image" content={process.env.PUBLIC_URL+"/favicon.svg"} />
          <meta property="og:description" content="Search players on PowerRankings.gg!" />
        </Helmet>

        {this.state.players && Object.entries(this.state.players).length > 0 ?
          <>
            <TextField
              fullWidth
              style={{marginBottom: 2}}
              placeholder={i18n.t("search-in")+Object.entries(this.state.players).length+i18n.t("search-players")}
              variant="outlined"
              type="search"
              onChange={(e)=>this.search(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Autocomplete
              options={Object.entries(countriesJson)}
              getOptionLabel={(option) => option[1].name + " ("+option[1].native+")"}
              renderOption={(option) =>
                <div style={{"contentVisibility": "auto", "containIntrinsicSize": "24px", display: "flex"}}>
                  <img style={{placeSelf: "center", marginRight: "6px"}} width="24px" height="16px" src={`https://raw.githubusercontent.com/joaorb64/tournament_api/multigames/country_flag/${option[0].toLowerCase()}.png`} />
                  {option[1].name + " ("+option[1].native+")"}
                </div>
              }
              onChange={(event, newValue)=>{
                this.state.filterCountry = newValue;
                this.search()
              }}
              renderInput={(params) => <TextField {...params} label={"Filter by country"} variant="outlined"/>}
            />

            <Autocomplete
              options={Object.entries(GAME_ASSETS[this.props.game].config.character_to_codename)}
              getOptionLabel={(option) => option[0]}
              renderOption={(option) =>
                <div style={{"contentVisibility": "auto", "containIntrinsicSize": "24px", display: "flex"}}>
                  <img style={{placeSelf: "center", marginRight: "6px"}} width="24px" height="24px" src={GetCharacterAsset(this.props.game, option[1].codename, 0, "icon")} />
                  {option[0]}
                </div>
              }
              onChange={(event, newValue)=>{
                this.state.filterCharacter = newValue;
                this.search()
              }}
              renderInput={(params) => <TextField {...params} label={"Filter by character"} variant="outlined"/>}
            />

            <Grid container>
              {Object.values(this.state.filtered).slice(0, 64).map((player, i) => (
                <PlayerElement game={this.props.game} onClick={()=>this.openPlayerModal(player)} player={player} />
              ))}
            </Grid>
          </>
          :
          <div class="loader"></div>
        }
        <PlayerModal game={this.props.game} ref={this.playerModal} open={this.state.playerModalOpened} closeModal={this.closePlayerModal.bind(this)} player={this.state.playerModalPlayer} leagues={this.props.leagues} allplayers={this.props.allplayers} alltournaments={this.props.alltournaments} history={this.props.history} />
      </Container>
    )
  }
};

export default Players