import React, { Component } from 'react'
import styles from './contacts.module.css'
import { Link, useParams, useLocation } from 'react-router-dom';
import LeagueSelector from './leagueselector';
import moment from "../../node_modules/moment-timezone/moment-timezone";
import {GetCharacterCodename, GetCharacterEyeHeight, GetPlayerSkin} from "../globals";
import LazyLoad from 'react-lazyload';
import { Grid, Paper, Box, Card, CardActionArea, Typography, BottomNavigation,
  BottomNavigationAction, Container } from '@material-ui/core';
import PlayerElement from './playerElement';
import { useTheme, withStyles, withTheme } from '@material-ui/core/styles';
import { ThemeProvider } from "@material-ui/styles";
import PlayerModal from './playermodal';
import { PureComponent } from 'react';
import i18n from '../locales/i18n';
import GameAsset from './GameAsset';
const queryString = require("query-string");

let useStyles = (props) => ({
  userNick: {
      lineHeight: "normal",
      [props.breakpoints.down("sm")]: {
          fontSize: 12
      }
  },
  userName: {
      [props.breakpoints.down("sm")]: {
          fontSize: 10
      }
  },
  top3Outer: {
    height: 240,
    [props.breakpoints.down("sm")]: {
      height: 220
    },
    [props.breakpoints.up("lg")]: {
      height: 340
    }
  }
});

class PlayerRanking extends PureComponent {
  state = {
    selectedLeague: 0,
    players: [],
    top3Colors: ["#D6AF36", "#D7D7D7", "#A77044"],
    top3Colors2: ["#AF9500", "#B4B4B4", "#6A3805"],
    top3Colors3: ["#C9B037", "#A7A7AD", "#824A02"],
    pagination: 0
  }

  constructor() {
    super();
    this.playerModal = React.createRef();
  }

  componentDidMount() {
    if(this.props.ranking){
      this.state.players = this.props.ranking;
    } else {
      this.state.players = null;
    }

    if(this.props.updateTime){
      this.state.updateTime = this.props.updateTime;
    } else {
      this.state.updateTime = null;
    }

    this.setState(this.state);
  }

  componentDidUpdate(prevProps){
    if(this.props.ranking && this.props.ranking.length > 0){
      console.log(this.props.match.params)
      if(this.props.match.params["player_id"] && this.playerModal.current.state.open == false){
        console.log(this.props.match.params["player_id"])

        let div = document.getElementById("ranking_player_"+this.props.ranking.findIndex((p)=>p.league_id == this.props.match.params["player_id"]));
        console.log(div)

        if(div)
          window.scrollTo({behavior: "smooth", top: div.offsetTop})

        let p = this.props.ranking.find((p)=>p.league_id == this.props.match.params["player_id"]);
        console.log(p);

        if(p)
          this.playerModal.current.setState({open: true, player: p});
      }

      if(this.props.ranking != prevProps.ranking){
        this.setState({pagination: 0});
      }
    }
  }

  getCharCodename(playerData, id){
    let skin = 0;

    if(playerData.hasOwnProperty("skins")){
      skin = playerData["skins"][playerData["mains"][id]];
      if(skin == undefined){
        skin = 0;
      }
    }
    
    return GetCharacterCodename(this.props.game, playerData["mains"][id])+"_0"+skin;
  }

  getCharName(name){
    return name.toLowerCase().replace(/ /g, "");
  }

  getTwitterHandle(twitter){
    let parts = twitter.split('/');
    return parts[parts.length-1];
  }

  normalizePlayerName(name){
    return name.normalize("NFKD").replace(/ /g, '_').replace(/@/g, "_At_").replace(/~/g, "_Tilde_").replace(RegExp('[^0-9a-zA-Z_-]'), '').replace("|", "")
  }

  openPlayerModal(player){
    window.history.replaceState(null, null,
      '/'+this.props.game+'/leagues/'+this.props.match.params["id"]+'/'+
      this.props.match.params["tab"]+"/"+player.league_id
    );
    
    this.playerModal.current.setState({open: true, player: player});
  }

  closePlayerModal(historyGoBack=true){
    window.history.replaceState(null, null,
      '/'+this.props.game+'/leagues/'+this.props.match.params["id"]+'/'+
      this.props.match.params["tab"]+"/"
    );
  }

  render (){
    const theme = this.props.theme;
    const { classes } = this.props;

    this.state.players = this.props.ranking;
    this.state.updateTime = this.props.updateTime;

    if((this.state.pagination+1)*50 <= this.state.players.length){
      this.timeout = setTimeout(function(){
        if(this){
          this.setState({pagination: this.state.pagination+1});
          this.timeout = null;
        }
      }.bind(this), 5000);
    }

    let fullscreenStyle = {
      position: "absolute",
      zIndex: 99999,
      top: 0, left: 0, right: 0, bottom: 0,
      backgroundColor: theme.palette.background.default,
      margin: "0",
      maxWidth: "unset"
    }

    let searchParams = queryString.parse(this.props.history.location.search);

    return(
      <div>
        {this.state.players && this.state.players.length > 0 ?
          <Container maxWidth="lg" disableGutters
          style={ searchParams.fullscreen ? fullscreenStyle : null}>
            {this.props.rankingName && !searchParams.fullscreen ?
              <Paper maxWidth="lg" style={{padding: 8, margin: 2}}>
                <Typography variant="body2" color="textSecondary" align="center">
                  {this.props.rankingName}
                  {this.props.rankingType ? " ("+this.props.rankingType+")" : ""}
                </Typography>
                <Typography variant="body2" color="textSecondary" align="center">
                  {this.props.alltimes || this.props.rankingStartTime == null ?
                    <>{"All times"}</>
                    :
                    <>{i18n.t("dateFrom") + " " + i18n.t("date_format", {date: moment.unix(this.props.rankingStartTime).toDate()}) + " " + i18n.t("dateFromAfter") + " " + i18n.t("dateTo") + " " + i18n.t("date_format", {date: moment.unix(this.props.rankingEndTime).toDate()}) + " " + i18n.t("dateToAfter")}</>
                  }
                </Typography>
              </Paper>
              :
              null
            }
            <Grid container>
              {this.state.players.slice(0, searchParams.regular ? 0 : 3).map((player, i) => (
                <Grid item lg={4} md={4} sm={4} xs={12} style={{padding: "2px", overflow: "hidden"}}>
                  <Card style={{
                    position: "relative", overflow: "hidden", backgroundColor: this.state.top3Colors[i]
                  }}>
                    <CardActionArea
                      onClick={()=>this.openPlayerModal(player)}
                      dataToggle="modal"
                      data-target="#playerModal"
                      key={this.state.selectedLeague+'_'+i}
                      id={"ranking_player_"+i}
                      className={classes.top3Outer}
                      style={{
                        position: "relative", overflow: "hidden", textAlign: "center"
                      }}>
                      <div style={{
                        backgroundColor: this.state.top3Colors2[i], position: "absolute",
                        top: 0, bottom: 0, left: 0, right: 0,
                        clipPath: "polygon(0 0, 0% 100%, 100% 100%)"
                      }}></div>
                      <div style={{
                        backgroundColor: this.state.top3Colors3[i], position: "absolute",
                        top: 0, bottom: 0, left: 0, right: 0,
                        clipPath: "polygon(0 60%, 0% 100%, 100% 100%)"
                      }}></div>
                      <div style={{position: "absolute", height: "calc(100% - 60px)", width: "100%", top: 0}}>
                        <GameAsset game={this.props.game} character={player["mains"][0]} skin={GetPlayerSkin(player, 0)} asset={"full"}></GameAsset>
                      </div>
                    
                      <div style={{
                        backgroundColor: theme.palette.background.paper,
                        alignItems: "center", display: "flex", flexDirection: "column",
                        height: "60px", position: "absolute", left: "0px", right: "0px", bottom: 0, justifyContent: "center",
                        paddingLeft: "10px", paddingRight: "10px"
                      }}>
                        <Box display="flex" justifyContent="center" noWrap>
                          {player.org ?
                            <Typography noWrap variant="h6" color="secondary" className={classes.userNick}>{player.org}&nbsp;</Typography>
                            :
                            null
                          }
                          <Typography noWrap variant="h6" color="textPrimary" className={classes.userNick}>{player.name}</Typography>
                        </Box>
                        <Typography noWrap variant="body2" color="textSecondary" className={classes.userName} style={{lineHeight: "normal"}}>
                          {player.full_name}
                        </Typography>
                      </div>

                      <div style={{
                        width: "200px", height: "200px", position: "absolute", 
                        backgroundColor: theme.palette.background.paper, top: -110, left: -110,
                        transform: "rotate(-45deg)"
                      }}>
                      </div>
                      <div style={{
                        width: "100px", height: "100px", position: "absolute", top: 0
                      }}>
                        <div style={{
                          width: 100, height: 100, backgroundImage: `url(${process.env.PUBLIC_URL}/icons/rank${i+1}.png)`,
                          backgroundSize: "cover", position: "absolute"
                        }}></div>
                      </div>

                      <div style={{
                        position: "absolute", left: "0px", right: "0px", bottom: "60px",
                        color: "white", display: "flex", whiteSpace: "nowrap", lineHeight: "32px"
                      }}>
                        <div style={{
                          display: "flex", flexGrow: 1, flexWrap: "wrap", alignSelf: "flex-end",
                          padding: "10px", filter: "drop-shadow(2px 2px 0px black)",
                        }}>
                          {player.mains.length > 0 ?
                            player.mains.slice(1).map((main, i)=>(
                              <div style={{
                                backgroundImage: `url(${process.env.PUBLIC_URL}/portraits/${this.props.game}/chara_2_${this.getCharCodename(player, i+1)}.png)`,
                                width: "32px", height: "32px", backgroundPosition: "center", backgroundSize: "cover",
                                flexGrow: 0, display: "inline-block"
                              }}></div>
                            ))
                            :
                            null
                          }
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-end"}}>
                          <div style={{
                            display: "flex", justifyContent: "flex-end",
                            filter: "drop-shadow(0px 0px 2px black)"
                          }}>
                            {player.country_code && player.country_code != "null" ?
                            <div class="" style={{
                              width: "48px", display: "flex", justifyContent: "center", alignItems: "center", padding: "8px", alignSelf: "flex-end"
                            }}>
                              <div class="flag" style={{
                                backgroundImage: `url(https://raw.githubusercontent.com/joaorb64/tournament_api/multigames/country_flag/${player.country_code.toLowerCase()}.png)`
                              }}><span>{player.country_code}</span></div>
                            </div>
                            :
                            null
                          }
                          {player.state && player.state != "null" ?
                            <div class="" style={{
                              width: "48px", display: "flex", justifyContent: "center", alignItems: "center", padding: "8px", alignSelf: "flex-end"
                            }}>
                              <div class="flag" style={{
                                backgroundImage: `url(https://raw.githubusercontent.com/joaorb64/world-state-flags/main/out/${player.country_code}/${player.state}.png)`
                              }}><span>{player.state}</span></div>
                            </div>
                            :
                            null
                          }
                          </div>
                          <div style={{
                            fontSize: "1.2rem", backgroundColor: "black", color: "white", textAlign: "right",
                            alignSelf: "flex-end", padding: "6px", paddingTop: "2px", paddingBottom: "2px"
                          }}>
                            {player.score} pts.
                          </div>
                        </div>
                      </div>

                      {player.avatar ?
                        <div style={{
                          backgroundImage: "url("+player.avatar+")",
                          width: "80px", height: "80px", backgroundSize: "cover", backgroundPosition: "center",
                          borderRadius: "100%", position: "absolute", right: 10, top: 10, border: "2px #f0f0f0 solid",
                          backgroundColor: "white"
                        }}>
                        </div>
                      :
                        null
                      }
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>

            <Grid container>
              {this.state.players.slice(searchParams.regular ? 0 : 3, searchParams.player_limit ? parseInt(searchParams.player_limit) : (this.state.pagination+1) * 50).map((player, i)=>(
                <PlayerElement game={this.props.game} player={player} onClick={()=>this.openPlayerModal(player)} />
              ))}
            </Grid>
            {console.log("rerender")}

            <div style={{textAlign: "right", color: "white"}}>
              <div class="col-12" style={{padding: "0 0px"}}>
                Dados atualizados em: {moment(this.state.updateTime).subtract(3, "hours").format("DD/MM/YY HH:mm") + " (GMT-3)"}
              </div>
            </div>
          </Container>
          :
            this.state.updateTime ?
              <Paper style={{padding: 8}}>
                No ranking was found for this league in Braacket. Please contact the league's mantainer.
              </Paper>
            :
            <div class="loader"></div>
        }
        <PlayerModal game={this.props.game} ref={this.playerModal} open={this.state.playerModalOpened} closeModal={this.closePlayerModal.bind(this)} leagues={this.props.contacts} allplayers={this.props.allplayers} alltournaments={this.props.alltournaments} history={this.props.history} />
      </div>
    )
  }
};

export default withTheme(withStyles(useStyles)(PlayerRanking))